<template>
  <v-dialog v-model="dialog" width="1000">
    <v-card>
      <v-card-text>
        <v-container class="pa-14">
          <v-row class="text-center" v-if="user.avatar_url">
            <v-col cols="6" class="mx-auto">
              <div id="preview" class="text-center">
                <v-img
                  class="mx-auto rounded-circle"
                  height="380"
                  v-if="user.avatar_url"
                  :src="user.avatar_url"
                />
              </div>
            </v-col>
          </v-row>
          <v-row class="mt-5">
            <v-col cols="12">
              <v-file-input
                class="pl-1"
                prepend-icon="mdi-camera"
                v-model="image"
                label="Upload image"
                @change="onChangeFileUpload"
                @click:clear="clearImage"
              ></v-file-input>
              <div class="text-h6">
                {{ lang.imageDisplay }}
              </div>
            </v-col>
          </v-row>
          <v-row class="mt-14">
            <v-col cols="12" class="text-h4 blue--text">
              {{ lang.information }}
            </v-col>
          </v-row>
          <v-row class="mt-8">
            <v-col cols="3" class="h4 pt-5 text-h5">
              {{ lang.full_name }}
            </v-col>
            <v-col cols="9">
              <HnrInput
                v-model="user.full_name"
                :text="user.full_name"
              ></HnrInput>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="3" class="h4 pt-5 text-h5">
              {{ lang.email }}
            </v-col>
            <v-col cols="9">
              <HnrInput v-model="user.email" :text="user.email"></HnrInput>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="3" class="h4 pt-5 text-h5">
              {{ lang.birthday }}
            </v-col>
            <v-col cols="9">
              <HnrInput
                v-model="user.birthday"
                :text="user.birthday"
              ></HnrInput>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="3" class="h4 pt-5 text-h5">
              {{ lang.phone_number }}
            </v-col>
            <v-col cols="9">
              <HnrInput
                v-model="user.phone_number"
                :text="user.phone_number"
              ></HnrInput>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="3" class="h4 pt-5 text-h5">
              {{ lang.info }}
            </v-col>
            <v-col cols="9">
              <HnrTextarea
                height="400px"
                v-model="user.info_content"
                :text="user.info_content"
              ></HnrTextarea>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="3" class="h4 pt-5 text-h5">
              {{ lang.fb_link }}
            </v-col>
            <v-col cols="9">
              <HnrInput v-model="user.fb_link" :text="user.fb_link"></HnrInput>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="3" class="h4 pt-5 text-h5">
              {{ lang.insta_link }}
            </v-col>
            <v-col cols="9">
              <HnrInput
                v-model="user.insta_link"
                :text="user.insta_link"
              ></HnrInput>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="3" class="h4 pt-5 text-h5">
              {{ lang.yt_link }}
            </v-col>
            <v-col cols="9">
              <HnrInput v-model="user.yt_link" :text="user.yt_link"></HnrInput>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="3" class="h4 pt-5 text-h5">
              {{ lang.pint_link }}
            </v-col>
            <v-col cols="9">
              <HnrInput
                v-model="user.pinterest_link"
                :text="user.pinterest_link"
              ></HnrInput>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="3" class="h4 pt-5 text-h5">
              {{ lang.zalo_link }}
            </v-col>
            <v-col cols="9">
              <HnrInput
                v-model="user.zalo_link"
                :text="user.zalo_link"
              ></HnrInput>
            </v-col>
          </v-row>
          <v-row>
            <v-btn
              text
              @click="updateUser"
              outlined
              color="indigo"
              width="150px"
              class="mx-auto"
            >
              {{ lang.btn }}
            </v-btn>
          </v-row>
        </v-container>
      </v-card-text>

      <v-divider></v-divider>
    </v-card>
  </v-dialog>
</template>

<script>
import HnrTextarea from "@/components/elements/hnr-textarea.vue";
import HnrInput from "@/components/elements/hnr-input";
import { GET_USER_INFO, UPDATE_USER_INFO } from "@/store/user.module";
import { UPLOAD_FLASHCARD_IMAGE } from "@/store/new.module";

export default {
  name: "updateUserInfo",
  components: {
    HnrTextarea,
    HnrInput
  },
  props: {
    dialog: Boolean,
    id: Number
  },
  data: () => ({
    lang: {
      information: "Thông tin chi tiết",
      btn: "Lưu dữ liệu",
      phone_number: "Số điện thoại",
      birthday: "Ngày sinh",
      email: "Email",
      full_name: "Họ tên đầy đủ",
      info: "Thông tin giới thiệu",
      fb_link: "url facebook",
      insta_link: "url insta",
      yt_link: "url youtube",
      pint_link: "url pinterest",
      zalo_link: "url zalo",
      imageDisplay: "(547px x 1102px)"
    },
    file: null,
    cardImg: "",
    image: {}
  }),
  computed: {
    user() {
      return this.$store.getters.userInfo;
    }
  },
  watch: {
    id(val) {
      this.$store.dispatch(GET_USER_INFO, { id: val });
    },
    user(val) {
      this.cardImg = val.avatar_url;
    }
  },
  methods: {
    clearImage() {
      this.cardImg = "";
    },
    onChangeFileUpload() {
      this.user.avatar_url = URL.createObjectURL(this.image);
    },
    async updateUser() {
      let formData = new FormData();
      formData.append("file", this.image);
      await this.$store
        .dispatch(UPLOAD_FLASHCARD_IMAGE, formData)
        .then(data => {
          this.user.avatar_url = data.data.data;
        })
        .catch(err => {
          console.log("err", err);
        });
      await this.$store.dispatch(UPDATE_USER_INFO, this.user);
      this.user.avatar_url = "";
      this.dialog = false;
    }
  }
};
</script>

<style scoped></style>
